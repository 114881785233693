import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import '../scss/Main.scss';
import Button from '../components/Button';

function ServicePage(props) {

    const data = useStaticQuery(graphql`
    query MyQuery {
      heroImg: allFile(
        filter: {relativeDirectory: {eq: "serviceImages"}}
        sort: {order: ASC, fields: name}
      ) {
        edges {
          node {
            name
            childImageSharp {
              fields {
                exif {
                  raw {
                    image {
                      ImageDescription
                    }
                  }
                }
              }
              gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      anr: allFile(
        filter: {relativeDirectory: {eq: "servicePages/additions_renovations"}}
        sort: {order: ASC, fields: name}
      ) {
        edges {
          node {
            name
            childImageSharp {
              fields {
                exif {
                  raw {
                    image {
                      ImageDescription
                    }
                  }
                }
              }
              gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      basements: allFile(
        filter: {relativeDirectory: {eq: "servicePages/basements"}}
        sort: {order: ASC, fields: name}
      ) {
        edges {
          node {
            name
            childImageSharp {
              fields {
                exif {
                  raw {
                    image {
                      ImageDescription
                    }
                  }
                }
              }
              gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      bathrooms: allFile(
        filter: {relativeDirectory: {eq: "servicePages/bathrooms"}}
        sort: {order: ASC, fields: name}
      ) {
        edges {
          node {
            name
            childImageSharp {
              fields {
                exif {
                  raw {
                    image {
                      ImageDescription
                    }
                  }
                }
              }
              gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      dnf: allFile(
        filter: {relativeDirectory: {eq: "servicePages/decks_fences"}}
        sort: {order: ASC, fields: name}
      ) {
        edges {
          node {
            name
            childImageSharp {
              fields {
                exif {
                  raw {
                    image {
                      ImageDescription
                    }
                  }
                }
              }
              gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      drynframe: allFile(
        filter: {relativeDirectory: {eq: "servicePages/framing_drywall"}}
        sort: {order: ASC, fields: name}
      ) {
        edges {
          node {
            name
            childImageSharp {
              fields {
                exif {
                  raw {
                    image {
                      ImageDescription
                    }
                  }
                }
              }
              gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      flooring: allFile(
        filter: {relativeDirectory: {eq: "servicePages/flooring"}}
        sort: {order: ASC, fields: name}
      ) {
        edges {
          node {
            name
            childImageSharp {
              fields {
                exif {
                  raw {
                    image {
                      ImageDescription
                    }
                  }
                }
              }
              gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      kitchens: allFile(
        filter: {relativeDirectory: {eq: "servicePages/kitchens"}}
        sort: {order: ASC, fields: name}
      ) {
        edges {
          node {
            name
            childImageSharp {
              fields {
                exif {
                  raw {
                    image {
                      ImageDescription
                    }
                  }
                }
              }
              gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      painting: allFile(
        filter: {relativeDirectory: {eq: "servicePages/painting"}}
        sort: {order: ASC, fields: name}
      ) {
        edges {
          node {
            name
            childImageSharp {
              fields {
                exif {
                  raw {
                    image {
                      ImageDescription
                    }
                  }
                }
              }
              gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      rsg: allFile(
        filter: {relativeDirectory: {eq: "servicePages/roofing_siding_gutters"}}
        sort: {order: ASC, fields: name}
      ) {
        edges {
          node {
            name
            childImageSharp {
              fields {
                exif {
                  raw {
                    image {
                      ImageDescription
                    }
                  }
                }
              }
              gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      snc: allFile(
        filter: {relativeDirectory: {eq: "servicePages/stonework_concrete"}}
        sort: {order: ASC, fields: name}
      ) {
        edges {
          node {
            name
            childImageSharp {
              fields {
                exif {
                  raw {
                    image {
                      ImageDescription
                    }
                  }
                }
              }
              gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      wnd: allFile(
        filter: {relativeDirectory: {eq: "servicePages/windows_doors"}}
        sort: {order: ASC, fields: name}
      ) {
        edges {
          node {
            name
            childImageSharp {
              fields {
                exif {
                  raw {
                    image {
                      ImageDescription
                    }
                  }
                }
              }
              gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      nMore: allFile(
        filter: {relativeDirectory: {eq: "servicePages/z_and_more"}}
        sort: {order: ASC, fields: name}
      ) {
        edges {
          node {
            name
            childImageSharp {
              fields {
                exif {
                  raw {
                    image {
                      ImageDescription
                    }
                  }
                }
              }
              gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
`);

    const renderBody = (index) => {
      switch(index){
        case 0:
          return(
            anrArr.map((row) => {
              return row;
            })
          );
          break;
        case 1:
          return(
            basementsArr.map((row) => {
              return row;
            })
          );
          break;
        case 2: 
          return(
            bathroomsArr.map((row) => {
              return row;
            })
          );
          break;
        case 3: 
          return(
            dnfArr.map((row) => {
              return row;
            })
          );
          break;        
        case 4:
          return(
            flooringArr.map((row) => {
              return row;
            })
          );
          break;
        case 5: 
          return(
            drynframeArr.map((row) => {
              return row;
            })
          );  
          break;

        case 6:
          return(
            kitchensArr.map((row) => {
              return row;
            })
          );
          break;
        case 7: 
          return(
            paintingArr.map((row) => {
              return row;
            })
          );
          break;
        case 8: 
          return(
            rsgArr.map((row) => {
              return row;
            })
          );
          break;
        case 9:
          return(
            sncArr.map((row) => {
              return row;
            })
          );
          break;
        case 10: 
          return(
            wndArr.map((row) => {
              return row;
            })
          );
          break;
        case 11:
          return(
            nMoreArr.map((row) => {
              return row;
            })
          );
          break;
        default: 
          console.log("an error has occured you shouldnt be here!")
          return;
      }
    }
    
    //creates an array of gatsby images
    const headerImageArr = data.heroImg.edges.map((edge, index) => {
        return(
            <div className="service-header-image-container">
                <GatsbyImage className={`service-image${index} s-img-container`} imgClassName={`s-img-${index}`} image={getImage(edge.node)} alt={edge.node.childImageSharp.fields.exif.raw.image.ImageDescription} />
                <div className="img-text-container">
                  <h1 className="service-title">{(edge.node.name.charAt(0) === 'z') ? edge.node.name.substring(1) : edge.node.name}</h1>
                  <h2 className="service-tagline"> LETS REIMAGINE YOUR HOME</h2>
                  <Button buttonType="2" linkType="gatsby" to="/contact" text="LETS GET BUILDING &thinsp; &rarr;" />
                </div>
            </div>
        )
    });

    //additions and renovations = anr
    //just an array of gatsby images
    const anrArr = data.anr.edges.map((edge, index) => {
      return(
        //sp = service page
        <div className={`service-imgNtext-container ${(index%2 == 0) ? '' : 'flowReverse'}`}>
          <div className="service-img-text-container">
            <h3>
              {edge.node.name.substring(1)}
            </h3>
            <p>
              {edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}
            </p>
            <div className="btn-wrapper">
              <Button buttonType="1" linkType="gatsby" to="/contact" text="GET IN TOUCH &thinsp;&rarr;"/>
            </div>
          </div>
          <div id={`anr-img${index}`} className="sp-img-wrapper">
            <GatsbyImage image={getImage(edge.node)} alt={edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}/> 
          </div>
        </div>
        
      )
    });

    const basementsArr = data.basements.edges.map((edge, index) => {
      return (
        <div className={`service-imgNtext-container ${(index%2 == 0) ? '' : 'flowReverse'}`}>
          <div className="service-img-text-container">
            <h3>
              {edge.node.name.substring(1)}
            </h3>
            <p>
              {edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}
            </p>
            <div className="btn-wrapper">
              <Button buttonType="1" linkType="gatsby" to="/contact" text="GET IN TOUCH &thinsp;&rarr;"/>
            </div>
          </div>
          <div id={`basements-img${index}`} className="sp-img-wrapper">
            <GatsbyImage image={getImage(edge.node)} alt={edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}/> 
          </div>
        </div>
      )
    });

    const bathroomsArr = data.bathrooms.edges.map((edge, index) => {
      return (
        <div className={`service-imgNtext-container ${(index%2 == 0) ? '' : 'flowReverse'}`}>
          <div className="service-img-text-container">
            <h3>
              {edge.node.name.substring(1)}
            </h3>
            <p>
              {edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}
            </p>
            <div className="btn-wrapper">
              <Button buttonType="1" linkType="gatsby" to="/contact" text="GET IN TOUCH &thinsp;&rarr;"/>
            </div>
          </div>
          <div id={`bathroom-img${index}`} className="sp-img-wrapper">
            <GatsbyImage className="bathroom-img-container" imgClassName={`bathroom-img${index}`} image={getImage(edge.node)} alt={edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}/> 
          </div>
        </div>
      )
    });

    // decks and fences = dnf
    const dnfArr = data.dnf.edges.map((edge, index) => {
      return (
        <div className={`service-imgNtext-container ${(index%2 == 0) ? '' : 'flowReverse'}`}>
          <div className="service-img-text-container">
            <h3>
              {edge.node.name.substring(1)}
            </h3>
            <p>
              {edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}
            </p>
            <div className="btn-wrapper">
              <Button buttonType="1" linkType="gatsby" to="/contact" text="GET IN TOUCH &thinsp;&rarr;"/>
            </div>
          </div>
          <div id={`dnf-img${index}`} className="sp-img-wrapper">
            <GatsbyImage className="dnf-img-container" imgClassName={`dnf-img${index}`} image={getImage(edge.node)} alt={edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}/> 
          </div>
        </div>
      )
    });

    // drywall and framing = drynframe
    const drynframeArr = data.drynframe.edges.map((edge, index) => {
      return (
        <div className={`service-imgNtext-container ${(index%2 == 0) ? '' : 'flowReverse'}`}>
          <div className="service-img-text-container">
            <h3>
              {edge.node.name.substring(1)}
            </h3>
            <p>
              {edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}
            </p>
            <div className="btn-wrapper">
              <Button buttonType="1" linkType="gatsby" to="/contact" text="GET IN TOUCH &thinsp;&rarr;"/>
            </div>
          </div>
          <div id={`drynframe-img${index}`} className="sp-img-wrapper">
            <GatsbyImage className="drynframe-img-container" imgClassName={`drynframe-img${index}`} image={getImage(edge.node)} alt={edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}/> 
          </div>
        </div>
      )
    });

    const flooringArr = data.flooring.edges.map((edge, index) => {
      return (
        <div className={`service-imgNtext-container ${(index%2 == 0) ? '' : 'flowReverse'}`}>
          <div className="service-img-text-container">
            <h3>
              {edge.node.name.substring(1)}
            </h3>
            <p>
              {edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}
            </p>
            <div className="btn-wrapper">
              <Button buttonType="1" linkType="gatsby" to="/contact" text="GET IN TOUCH &thinsp;&rarr;"/>
            </div>
          </div>
          <div id={`flooring-img${index}`} className="sp-img-wrapper">
            <GatsbyImage image={getImage(edge.node)} alt={edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}/> 
          </div>
        </div>
      )
    });

    const kitchensArr = data.kitchens.edges.map((edge, index) => {
      return (
        <div className={`service-imgNtext-container ${(index%2 == 0) ? '' : 'flowReverse'}`}>
          <div className="service-img-text-container">
            <h3>
              {edge.node.name.substring(1)}
            </h3>
            <p>
              {edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}
            </p>
            <div className="btn-wrapper">
              <Button buttonType="1" linkType="gatsby" to="/contact" text="GET IN TOUCH &thinsp;&rarr;"/>
            </div>
          </div>
          <div id={`kitchen-img${index}`} className="sp-img-wrapper">
            <GatsbyImage className="kitchen-img-container" imgClassName={`kitchen-img${index}`} image={getImage(edge.node)} alt={edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}/> 
          </div>
        </div>
      )
    });

    const paintingArr = data.painting.edges.map((edge, index) => {
      return (
        <div className={`service-imgNtext-container ${(index%2 == 0) ? '' : 'flowReverse'}`}>
          <div className="service-img-text-container">
            <h3>
              {edge.node.name.substring(1)}
            </h3>
            <p>
              {edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}
            </p>
            <div className="btn-wrapper">
              <Button buttonType="1" linkType="gatsby" to="/contact" text="GET IN TOUCH &thinsp;&rarr;"/>
            </div>
          </div>
          <div id={`painting-img${index}`} className="sp-img-wrapper">
            <GatsbyImage image={getImage(edge.node)} alt={edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}/> 
          </div>
        </div>
      )
    });

    // roofing, siding, and gutters = rsg
    const rsgArr = data.rsg.edges.map((edge, index) => {
      return (
        <div className={`service-imgNtext-container ${(index%2 == 0) ? '' : 'flowReverse'}`}>
          <div className="service-img-text-container">
            <h3>
              {edge.node.name.substring(1)}
            </h3>
            <p>
              {edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}
            </p>
            <div className="btn-wrapper">
              <Button buttonType="1" linkType="gatsby" to="/contact" text="GET IN TOUCH &thinsp;&rarr;"/>
            </div>
          </div>
          <div id={`rsg-img${index}`} className="sp-img-wrapper">
            <GatsbyImage image={getImage(edge.node)} alt={edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}/> 
          </div>
        </div>
      )
    });

    //stonework and concrete = snc
    const sncArr = data.snc.edges.map((edge, index) => {
      return (
        <div className={`service-imgNtext-container ${(index%2 == 0) ? '' : 'flowReverse'}`}>
          <div className="service-img-text-container">
            <h3>
              {edge.node.name.substring(1)}
            </h3>
            <p>
              {edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}
            </p>
            <div className="btn-wrapper">
              <Button buttonType="1" linkType="gatsby" to="/contact" text="GET IN TOUCH &thinsp;&rarr;"/>
            </div>
          </div>
          <div id={`snc-img${index}`} className="sp-img-wrapper">
            <GatsbyImage image={getImage(edge.node)} alt={edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}/> 
          </div>
        </div>
      )
    });

    // // windows and doors = wnd
    const wndArr = data.wnd.edges.map((edge, index) => {
      return (
        <div className={`service-imgNtext-container ${(index%2 == 0) ? '' : 'flowReverse'}`}>
          <div className="service-img-text-container">
            <h3>
              {edge.node.name.substring(1)}
            </h3>
            <p>
              {edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}
            </p>
            <div className="btn-wrapper">
              <Button buttonType="1" linkType="gatsby" to="/contact" text="GET IN TOUCH &thinsp;&rarr;"/>
            </div>
          </div>
          <div id={`wnd-img${index}`} className="sp-img-wrapper">
            <GatsbyImage image={getImage(edge.node)} alt={edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}/> 
          </div>
        </div>
      )
    });

    const nMoreArr = data.nMore.edges.map((edge, index) => {
      return (
        <div className={`service-imgNtext-container ${(index%2 == 0) ? '' : 'flowReverse'}`}>
          <div className="service-img-text-container">
            <h3>
              {edge.node.name.substring(1)}
            </h3>
            <p>
              {edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}
            </p>
            <div className="btn-wrapper">
              <Button buttonType="1" linkType="gatsby" to="/contact" text="GET IN TOUCH &thinsp;&rarr;"/>
            </div>
          </div>
          <div id={`nMore-img${index}`} className="sp-img-wrapper">
            <GatsbyImage image={getImage(edge.node)} alt={edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}/> 
          </div>
        </div>
      )
    });

    //THIS IS WHAT IS ACTUALLY RETURNED AKA RENDERED!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! 
    return (
        <div className="service-page-container">
            {/* displays the correct header image wrt the service number sent in as a prop */}
            {headerImageArr[props.serviceNumber]}
            <div className="service-page-body">
              {
                renderBody(props.serviceNumber)
              }
            </div>
        </div>
    )
}

export default ServicePage
